.card {
  display: inline-block;
  width: calc(50% - 20px);
  margin: 0 10px 20px 10px;
}

.card.card-full-width {
  width: calc(100% - 20px);
}

.card-body {
  height: 100%;
}

.list-container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
